<template>
  <section class="contact">
    <b-container class="mb-5">
      <b-row class="contact-title">
        <b-col
          cols="12"
        >

          <h2 class="h2-titles">
            Entrar em contato
          </h2>

          <h5 class="h5-titles">
            Entre em contato conosco através de nossas redes sociais ou nos faça uma visita.
          </h5>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="6"
        >
          <div class="social-media">
            <social-media
              icon="whatsApp"
              tooltip-title="WhatsApp"
              size="40"
              :link="generateLinkWhatsApp(systemData.cellPhone)"
              margin="5"
              :target-blank="true"
            />
            <social-media
              icon="facebook"
              tooltip-title="Facebook"
              size="40"
              :link="systemData.facebook"
              margin="5"
              :target-blank="true"
            />
            <social-media
              icon="instagram"
              tooltip-title="Instagram"
              size="40"
              :link="systemData.instagram"
              margin="5"
              :target-blank="true"
            />
            <social-media
              icon="pinterest"
              tooltip-title="Pinterest"
              size="40"
              :link="systemData.pinterest"
              margin="5"
              :target-blank="true"
            />
          </div>
        </b-col>

        <b-col
          lg="6"
        >
          <div class="mb-4">
            <h3>Endereço</h3>

            <p>
              {{ systemData.address }},
              {{ systemData.numberAddress }},
              {{ systemData.complement }}<br>
              {{ systemData.zipCode | VMask('#####-###') }}<br>
              {{ systemData.district }},
              {{ systemData.city }} - {{ systemData.uf }}
            </p>
          </div>

          <div>
            <h3>Contatos</h3>

            <p>{{ systemData.phone | VMask('(##) ####-####') }}</p>
            <p>{{ systemData.cellPhone | VMask('(##) #####-####') }}</p>
            <p>{{ systemData.email }}</p>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-row>
        <l-map
          :zoom="zoom"
          :center="center"
        >
          <l-tile-layer :url="url" />
          <l-marker :lat-lng="markerLatLng">
            <l-popup>You're here!</l-popup>
          </l-marker>
        </l-map>
      </b-row>
    </b-container>
  </section>
</template>

<script>
/* eslint-disable global-require */
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import SocialMedia from '@/components/SocialMedia/SocialMedia.vue'
import { generateLinkWhatsApp } from '@/utils/whatsApp'
import {
  LMap, LTileLayer, LMarker, LPopup,
} from 'vue2-leaflet'
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: 'Contact',

  components: {
    BContainer,
    BRow,
    BCol,
    SocialMedia,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },

  data() {
    return {
      systemData: this.$store.state.system.systemData,
      generateLinkWhatsApp,

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 18,
      center: [-29.681732151169896, -51.120469023518034],
      markerLatLng: [-29.681732151169896, -51.120469023518034, { draggable: 'true' }],
    }
  },
}
</script>

<style scoped lang="scss">
@import './style.scss';
</style>
