<template>
  <a
    :href="getLink"
    :title="tooltipTitle"
    :target="getTargetBlank"
    :style="getLinkStyles"
  >
    <img
      :style="getImgStyles"
      :src="getIcon"
      alt=""
    >
  </a>
</template>

<script>
import facebook from '@/assets/img/icons/facebook.png'
import whatsApp from '@/assets/img/icons/whatsapp.png'
import instagram from '@/assets/img/icons/instagram.png'
import pinterest from '@/assets/img/icons/pinterest.png'

export default {
  name: 'SocialMedia',

  props: {
    icon: {
      type: String,
      default: '',
    },
    tooltipTitle: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '18',
    },
    link: {
      type: String,
      default: '',
    },
    margin: {
      type: String,
      default: '0',
    },
    targetBlank: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      facebook,
      whatsApp,
      instagram,
      pinterest,
    }
  },

  computed: {
    getLinkStyles() {
      return {
        textDecoration: 'none',
        margin: `${this.margin}px`,
      }
    },

    getImgStyles() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      }
    },

    getLink() {
      return this.link
    },

    getIcon() {
      switch (this.icon) {
        case 'facebook': return this.facebook
        case 'whatsApp': return this.whatsApp
        case 'instagram': return this.instagram
        case 'pinterest': return this.pinterest
        default: return ''
      }
    },

    getTargetBlank() {
      return this.targetBlank ? '_blank' : '_self'
    },
  },
}
</script>

<style scoped>

</style>
